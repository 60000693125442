import numeral from 'numeral';

// this is a numeral bug when numbers are tiny
const format = (amount, fstring) => {
  const a = Math.abs(amount) < 0.00001 ? 0 : amount;
  return numeral(a).format(fstring);
};

const prettyNumber = (amount) => {
  if (Math.abs(amount) > 1000000) return format(amount, '0.0a').toUpperCase();
  if (Math.abs(amount) > 10000) return format(amount, '0,0');
  if (Math.abs(amount) > 1000) return format(amount, '0,0.[0]');
  if (Math.abs(amount) > 100) return format(amount, '0,0.[00]');
  return format(amount, '0,0.0[00]');
};

export const prettyNumberPrice = (amount) => {
  if (Math.abs(amount) < 1) return format(amount, '0,0.00[0]'); // typically the price is /lb
  if (Math.abs(amount) > 1000) return format(amount, '0,0'); // typically the price is /mt
  return format(amount, '0,0.00');
};

export const prettyNumberRound = (amount) => {
  if (Math.abs(amount) < 1) return format(amount, '0,0.[00]');
  if (Math.abs(amount) < 10) return format(amount, '0,0.[0]');
  return format(amount, '0,0');
};

export default prettyNumber;
