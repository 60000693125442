import { Col, Row, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { Box, BoxColumn } from 'src/components/project/explore/summary/helpers';
import { currencyValuePrice } from 'src/utils/currency';
import { getMarketPriceLatestPost } from 'src/Query/marketPrice';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

const priceLabels = {
  circular: (filters, price) => ['Circular Select', 'Historial Transactions'],
  index: (filters, price) => ['Index', price.source?.toUpperCase()],
  index_asia: (filters, price) => ['Index (Asia)', price.source?.toUpperCase()]
};

export default function BalesPriceSummary() {
  const [filters] = useConciergeContextState(['explore', 'filters']);
  const priceFilters = [
    {
      ...filters,
      circular: true,
      type: 'circular'
    },
    {
      ...filters,
      circular: false,
      type: 'index'
    },
    {
      ...filters,
      circular: false,
      region_code__in: 'asia',
      type: 'asia_index'
    }
  ];
  const { data: latestPrices, isLoading } = useDebouncedQuery(
    ['market-price-latest', priceFilters],
    () => getMarketPriceLatestPost(priceFilters)
  );
  const loadingClass = useLoadingStateClass(isLoading);

  const [projectSettings] = useConciergeContextState([
    'explore',
    'projectSettings'
  ]);

  const rows = useMemo(() => {
    const rowList = (latestPrices || []).map((price) => {
      const labelsFn = priceLabels[price.price_type];
      const priceFilter = priceFilters.find((o) => o.type === price.price_type);
      const labels = labelsFn ? labelsFn(priceFilter, price) : ['Index', ''];
      return {
        ticker: price.market_price_ticker_id,
        label: labels[0],
        subLabel: labels[1],
        mid: (parseFloat(price.high) + parseFloat(price.low)) / 2,
        low: parseFloat(price.low),
        high: parseFloat(price.high),
        units: price.units,
        currency: price.currency
      };
    });

    return rowList;
  }, [projectSettings, latestPrices]);

  const labelColumnSpan = 8;
  const boxWidth = 150;
  return (
    <ExplorePanel className={loadingClass} pad>
      <Space direction="vertical" size={18}>
        <Row gutter={[8, 0]}>
          <Col span={labelColumnSpan}>
            <Typography.Text strong>Price Summary</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 0]} align="middle" wrap={false}>
          <Col span={labelColumnSpan}></Col>
          <BoxColumn width={boxWidth}>
            <Typography.Text strong align="middle">
              Low
            </Typography.Text>
          </BoxColumn>
          <BoxColumn width={boxWidth}>
            <Typography.Text strong>High</Typography.Text>
          </BoxColumn>
        </Row>
        {rows.map((row, index) => (
          <Row
            key={index}
            className="explore-summary--kpi-row"
            gutter={[16, 0]}
            align="middle"
            wrap={false}
          >
            <Col span={labelColumnSpan}>
              <Typography.Text strong>{row.label}</Typography.Text>
              <br />
              <Typography.Text type="secondary">
                {row.subLabel || ''}
              </Typography.Text>
            </Col>
            <BoxColumn width={boxWidth}>
              <Box className="neutral">
                <Typography.Text strong>
                  {row.low
                    ? currencyValuePrice(
                        row.low,
                        row.currency,
                        row.units?.toLowerCase()
                      )
                    : '-'}
                </Typography.Text>
              </Box>
            </BoxColumn>
            <BoxColumn width={boxWidth}>
              <Box className="neutral">
                <Typography.Text strong>
                  {row.high
                    ? currencyValuePrice(
                        row.high,
                        row.currency,
                        row.units?.toLowerCase()
                      )
                    : '-'}
                </Typography.Text>
              </Box>
            </BoxColumn>
          </Row>
        ))}
      </Space>
    </ExplorePanel>
  );
}

BalesPriceSummary.propTypes = {};
