import { fetch } from 'src/utils/client';
import dayjs from 'dayjs';
import { useQueries, useQuery } from '@tanstack/react-query';

const deleteKeys = (params, keys) => {
  if (!params) return params;
  const result = { ...params };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
};

// URLSearchParams treats spaces in a way that we don't want
const searchParams = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

export const objectKey = (params) =>
  params && JSON.stringify(params, Object.keys(params).sort());
const arrayKey = (params) => params.map((p) => objectKey(p)).join(';');

export const getMarketPriceTickers = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/market-price-ticker/?${searchParams(
      params
    )}`
  );

export const getMarketPriceTickersList = (paramsArray) =>
  Promise.all(
    paramsArray.map((params) =>
      fetch(
        `${
          process.env.REACT_APP_CIRCLE_API
        }/market-price-ticker/?${searchParams(params)}`
      ).catch(() => undefined)
    )
  );

export const getMarketPriceTickerSeries = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/market-price-ticker/${
      params.id
    }/series/?${searchParams(deleteKeys(params, ['id']))}`
  );

export const getMarketPriceTickerLatestPrice = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/market-price-ticker/${
      params.id
    }/latest-price/?${searchParams(deleteKeys(params, ['id']))}`
  );

export const getMarketPriceLatestPrice = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/market-price-value/latest-price/?${searchParams(params)}`
  );

export const getMarketPriceSeries = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/warehouse/market-price-value/series/?${searchParams(params)}`
  );

export const getMarketPriceSeriesPost = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/warehouse/market-price-value/series/`,
    {
      method: 'POST',
      body: JSON.stringify(params)
    }
  );

export const getMarketPriceLatestPost = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/warehouse/market-price-value/latest/`,
    {
      method: 'POST',
      body: JSON.stringify(params)
    }
  );

export const getMarketPriceLatestPriceList = (paramsArray) =>
  Promise.all(
    paramsArray.map((params) =>
      fetch(
        `${
          process.env.REACT_APP_CIRCLE_API
        }/market-price-value/latest-price/?${searchParams(params)}`
      ).catch(() => undefined)
    )
  );

export function useMarketPriceSeries(params) {
  const { data: series, isLoading } = useQuery(
    ['marketPriceSeries', objectKey(params)],
    async () => {
      try {
        return await getMarketPriceSeries(params);
      } catch (error) {
        if (error.status === 404) {
          // TODO: Any special handling for 404s?
          return [];
        }
        throw error;
      }
    },
    { retry: false, enabled: !!params }
  );
  return {
    isLoading: !!(params && isLoading),
    series
  };
}

const marketPriceFilters = {
  circularPcrResin: {
    form_code: 'resin_pellets',
    virgin: false,
    circular: true,
    extrapolate: false
  },
  pcrResin: {
    form_code: 'resin_pellets',
    virgin: false
  },
  virgin: {
    virgin: true
  },
  flakes: {
    form_code: 'regrind_flakes',
    virgin: false
  },
  bales: {
    form_code: 'bales',
    virgin: false
  }
};

const sampleUnits = {
  daily: {
    sample_units: 'days',
    sample_duration: 1
  },
  weekly: {
    sample_units: 'days',
    sample_duration: 7
  },
  monthly: {
    sample_units: 'days',
    sample_duration: 30
  }
};

export function useMarketPriceSeriesCollection(
  lineSpecs,
  filters,
  months,
  pricesSettings
) {
  const { form_code__in, ...filtersWithoutForm } = filters || {};

  const sampleUnitsItem =
    months >= 96
      ? sampleUnits.monthly
      : months > 12
      ? sampleUnits.weekly
      : sampleUnits.daily;
  const { sample_units, sample_duration } = sampleUnitsItem;

  const queryParams = lineSpecs
    .map(
      (o) =>
        (marketPriceFilters[o.type] || // only include it if we have filters or if it is in settings and not hidden
          pricesSettings?.[o.type]) &&
        !pricesSettings?.[o.type]?.hide && {
          ...filtersWithoutForm,
          ...(marketPriceFilters[o.type] || {}),
          sample_duration,
          ...o,
          sample_units,
          start_date: dayjs()
            .subtract(months, 'month')
            .toISOString()
            .split('T')[0],
          ...(pricesSettings?.[o.type] || {})
        }
    )
    .filter((o) => o);
  const typeParamsMap = queryParams.reduce((acc, curr) => {
    acc[curr.type] = curr;
    return acc;
  }, {});
  const { data, ...other } = useQuery({
    queryKey: ['marketPriceSeriesCollection', filters, months],
    queryFn: async () => getMarketPriceSeriesPost(queryParams),
    retry: false,
    enabled: !!filters
  });
  const zippedData =
    (Array.isArray(data) &&
      data.map((d, i) => ({
        ...typeParamsMap[d.type],
        data: d
      }))) ||
    undefined;
  return { data: zippedData, ...other };
}

export function useMultipleMarketPriceSeries(queryParams) {
  const results = useQueries({
    queries: queryParams.map((params) => ({
      queryKey: ['marketPriceSeries', objectKey(params)],
      queryFn: async () => {
        try {
          return await getMarketPriceSeries(params);
        } catch (error) {
          if (error.status === 404) {
            // TODO: Any special handling for 404s?
            return null;
          }
          throw error;
        }
      },
      retry: false,
      enabled: !!params
    }))
  });

  const isLoading = results.some((result) => result.isLoading);

  return {
    isLoading: !!(queryParams && isLoading),
    seriesList: results.map((result) => result.data)
  };
}

export function useMarketPriceLatestPrice(params) {
  const { data: price, isLoading } = useQuery(
    ['marketPriceLatestPrice', objectKey(params)],
    () => getMarketPriceLatestPrice(params),
    { retry: false }
  );
  return {
    isLoading: !!(params && isLoading),
    price
  };
}

export function useMarketPriceLatestPriceList(paramsArray) {
  const { data: prices, isLoading } = useQuery(
    ['marketPriceTickerLatestPriceList', arrayKey(paramsArray)],
    () => getMarketPriceLatestPriceList(paramsArray),
    { retry: false }
  );
  const source = 'various';

  return { isLoading, prices, source };
}

export const getMarketPriceReference = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/market-price-value/reference-price/?${searchParams(params)}`
  );

export function useMarketPriceReference(params) {
  const { data: price, isLoading } = useQuery(
    ['marketPriceReference', objectKey(params)],
    () => getMarketPriceReference(params),
    { retry: false }
  );
  return {
    isLoading,
    price
  };
}

export const getMarketPriceLatest = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/warehouse/market-price-value-latest/aggregate/?${searchParams(params)}`
  );

export function useMarketPriceLatest(params) {
  const { data: price, isLoading } = useQuery(
    ['marketPriceLatest', objectKey(params)],
    async () => {
      try {
        return await getMarketPriceLatest(params);
      } catch (error) {
        if (error.status === 404) {
          // TODO: Any special handling for 404s?
          return [];
        }
        throw error;
      }
    },
    { retry: false }
  );
  return {
    isLoading,
    price
  };
}

export const getMarketPriceLatestRaw = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/warehouse/market-price-value-latest/?${searchParams(params)}`
  );
