import React from 'react';

import { Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getMarketPriceLatestRaw } from 'src/Query/marketPrice';

const sorter = (a, b) => {
  if (typeof a === 'string') return a.localeCompare(b);
  if (typeof a === 'number') return a - b;
  return 0;
};

function CircularIndices() {
  const { data, isLoading: isActivityLoading } = useQuery(
    ['market-price-latest', 'circular'],
    () => getMarketPriceLatestRaw({ circular: true, all: true, rank: 1 })
  );
  return (
    <Table
      pagination={false}
      dataSource={data}
      columns={[
        {
          title: 'Name',
          dataIndex: 'source_name',
          sorter: (a, b) =>
            a.source_name ? a.source_name.localeCompare(b.source_name) : -1
        },
        {
          title: 'Type',
          dataIndex: 'type',
          sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : -1)
        },
        {
          title: 'Form',
          dataIndex: 'form',
          sorter: (a, b) => (a.form ? a.form.localeCompare(b.form) : -1)
        },
        {
          title: 'Region',
          dataIndex: 'region',
          sorter: (a, b) => (a.region ? a.region.localeCompare(b.region) : -1)
        },
        {
          title: 'Latest timestamp',
          dataIndex: 'timestamp',
          sorter: (a, b) =>
            a.timestamp ? a.timestamp.localeCompare(b.timestamp) : -1
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          sorter: (a, b) =>
            a.currency ? a.currency.localeCompare(b.currency) : -1
        },
        {
          title: 'High',
          dataIndex: 'high',
          sorter: (a, b) => (a.high ? a.high - b.high : -1)
        },
        {
          title: 'Low',
          dataIndex: 'low',
          sorter: (a, b) => (a.low ? a.low - b.low : -1)
        },
        {
          title: 'Units',
          dataIndex: 'units',
          sorter: (a, b) => (a.units ? a.units.localeCompare(b.units) : -1)
        },
        {
          title: 'Country',
          dataIndex: 'country_name',
          sorter: (a, b) =>
            a.country_name ? a.country_name.localeCompare(b.country_name) : -1
        },
        {
          title: 'City',
          dataIndex: 'city',
          sorter: (a, b) => (a.city ? a.city.localeCompare(b.city) : -1)
        }
      ]}
    />
  );
}

export default CircularIndices;
