import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Flex,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography
} from 'antd';
import React, { useMemo, useState } from 'react';
import {
  CheckCircleFilled,
  InfoCircleFilled,
  UserOutlined
} from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import FullScreenModal from 'src/components/layout/FullScreenModal';
import ProposalSummary from 'src/components/project/search/ProposalSummary';
import { Box, BoxColumn } from 'src/components/project/explore/summary/helpers';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';
import { QuoteDetails } from 'src/components/project/search/QuoteDetails';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import useUpdateProject from 'src/Mutation/useUpdateProject';
import useGetPopulatedProject from 'src/Query/useGetPopulatedProject';
import useGetProjectWorkflows from 'src/Query/useGetProjectWorkflows';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { ProjectTDSStatus } from 'src/components/project/search/constants';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import dayjs from 'dayjs';
import { isTdsFoodGrade } from 'src/utils/properties';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import { UnitSelect } from 'src/utils/units';
import MarkdownEditableText from 'src/utils/MarkdownEditableText';
import { currencyValuePrice } from 'src/utils/currency';

const tabQuestionMap = {
  brand: ['certs_match', 'years_in_business_match'],
  price: [],
  spec: [],
  supply: ['capacity_match']
};

export const matchesForTab = (project, tds, tab) => {
  if (!project?.settings?.submittedTdss?.[tds.uuid]?.[tab]) {
    return tab === 'supply' && isTdsFoodGrade(tds) ? 1 : 0;
  }

  const tdsTabContents = project.settings.submittedTdss[tds.uuid][tab];

  const questions = tabQuestionMap[tab];
  let matchCount = questions.reduce(
    (acc, question) => acc + (tdsTabContents[question] ? 1 : 0),
    0
  );
  const kpiCodes = Object.keys(project.filters?.kpis ?? {});

  switch (tab) {
    case 'spec':
      return (
        matchCount +
        kpiCodes.reduce((acc, kpiCode) => {
          const key = `${kpiCode.toLowerCase()}_match`;
          return acc + (tdsTabContents[key] ? 1 : 0);
        }, 0)
      );
    case 'supply':
      matchCount += isTdsFoodGrade(tds) ? 1 : 0;
      return matchCount;
    default:
      return matchCount;
  }
};

export const questionsForTab = (project, tds, tab) => {
  const questions = tabQuestionMap[tab];
  const kpiCodes = Object.keys(project.filters?.kpis ?? {});

  switch (tab) {
    case 'spec':
      return kpiCodes.length;
    case 'supply':
      return questions.length + 1; // Food grade is not stored in the project settings
    default:
      return questions.length;
  }
};

export const tdsPriceText = (tds, benchmark) => {
  const preferredLocation = tds.project_tds?.tds_location || tds.locations[0];
  const sortedPrices =
    preferredLocation?.price_logs?.sort((pl1, pl2) =>
      dayjs(pl1.date) > dayjs(pl2.date) ? 1 : -1
    ) || [];
  const latestPriceObj = sortedPrices.length
    ? sortedPrices[sortedPrices.length - 1]
    : null;

  const { price: latestPrice = null } = latestPriceObj ?? {};
  const total =
    latestPrice +
    (tds.project_tds.tariff || 0) +
    (tds.project_tds.freight || 0);
  const delta_percent = (total / benchmark - 1) * 100;

  return latestPrice ? (
    <>
      <Typography.Text strong>
        {currencyValuePrice(total, tds.currency)}
      </Typography.Text>
      <Typography.Text>
        &nbsp;({prettyNumberRound(delta_percent)}%)
      </Typography.Text>
    </>
  ) : (
    '--'
  );
};

const LIST_BOX_COLUMN_WIDTH = 114;
export default function ProposalModal() {
  const navigate = useNavigate();
  const isConcierge = useIsConcierge();
  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get('project');
  const status = searchParams.get('status');

  const [showingTdsId, setShowingTdsId] = useState(null);

  const filters = {
    status: [status || ProjectTDSStatus.PROPOSAL]
  };

  const {
    data: project,
    isLoading,
    isRefetching
  } = useGetPopulatedProject(projectUuid, filters);

  const { data: searchTDSs } = useGetWarehouseTdss(project?.filters, {
    enabled: !!project
  });

  const { settings } = project ?? {};

  const {
    mutate,
    isLoading: isUpdatingProject,
    messageContext
  } = useUpdateProject(projectUuid, filters);

  const { data: projectWorkflows, isLoadingWorkflows } =
    useGetProjectWorkflows(projectUuid);

  const {
    wfExited: _workflowExited,
    wfIn: _workflowIn,
    wfMetGoal: workflowMetGoal,
    wfTotal: workflowTotal
  } = useMemo(() => {
    let wfExited = 0;
    let wfIn = 0;
    let wfMetGoal = 0;
    let wfTotal = 0;

    (projectWorkflows || []).forEach((wf) => {
      wfExited += wf.analytics.exited.value;
      wfIn += wf.analytics.in.value;
      wfMetGoal += wf.analytics.met_goal.value;
      wfTotal += wf.analytics.total.value;
    });

    return {
      wfExited,
      wfIn,
      wfMetGoal,
      wfTotal
    };
  }, [projectWorkflows]);

  const loadingClass = useLoadingStateClass(isUpdatingProject || isRefetching);

  const responseTDSs = useMemo(() => project?.tdss ?? [], [project]);

  const searchResultCount = searchTDSs?.count || 0;

  const editableConfig = useMemo(
    () => (key) => ({
      text: settings?.proposal_summary,
      onChange: isConcierge
        ? (val) =>
            mutate({
              uuid: projectUuid,
              settings: {
                ...(settings ?? {}),
                [key]: val
              }
            })
        : undefined
    }),
    [isConcierge, settings]
  );

  const RightSideContent = useMemo(
    () => (
      <>
        {isConcierge && (
          <AdminConsoleLink app="api" type="project" uuid={projectUuid}>
            <Button title="Edit in Admin">Edit in Admin</Button>
          </AdminConsoleLink>
        )}
        <UnitSelect
          unitType="weight"
          invalidateQueries={[['project', projectUuid, 'populated']]}
        />
        <Link target="_blank" to={`/digital-rfq/${projectUuid}`}>
          <Button title="View Digital RFQ">View Digital RFQ</Button>
        </Link>
      </>
    ),
    [projectUuid]
  );

  if (isLoading) {
    return (
      <FullScreenModal
        title={project?.name || 'Project Quotes'}
        width="100%"
        open
        onBack={() => navigate(`/project/search?project=${projectUuid}`)}
        className={`search--proposal ${loadingClass}`}
        headerProps={{
          right: RightSideContent
        }}
      >
        <Skeleton />
      </FullScreenModal>
    );
  }

  const tagForTds = (tds) => {
    const tagText =
      tds?.project_tds?.tag || settings?.submittedTdss?.[tds.uuid]?.tag;
    if (tagText) return <Tag color="#2f54eb">{tagText}</Tag>;
    return undefined;
  };

  const benchmark = project.price;
  const benchmarkAllIn =
    project.price +
    project.target_tariff +
    // project.target_fee +
    project.target_freight;

  return (
    <>
      {messageContext}
      <FullScreenModal
        title={project?.name || 'Project Quotes'}
        width="100%"
        open
        onBack={() => navigate(`/project/search?project=${projectUuid}`)}
        className="search--proposal"
        headerProps={{
          right: RightSideContent
        }}
      >
        <Flex
          align="stretch"
          className={loadingClass}
          direction="horizontal"
          justify="center"
          style={{ minHeight: '100%' }}
        >
          <Flex
            className="search--proposal--list pb-2xxl"
            justify={showingTdsId ? 'end' : 'center'}
          >
            <Space
              className="mr-xl"
              direction="vertical"
              style={{ maxWidth: '960px' }}
            >
              <Typography.Title className="mb-lg mt-lg" level={3}>
                {project?.name || 'Project Quotes'}
              </Typography.Title>
              <div className="color-card color-card--info mb-lg">
                <MarkdownEditableText {...editableConfig('proposal_summary')} />
              </div>
              <ProposalSummary
                quotesCount={project.total_tds || responseTDSs.length}
                respondedAmount={workflowMetGoal}
                respondedPercent={prettyNumberRound(
                  (workflowMetGoal * 100) / workflowTotal
                )}
                rfqSentCount={workflowTotal}
                rfqSentPercent={prettyNumberRound(
                  (workflowTotal * 100) / searchResultCount
                )}
                searchCount={searchResultCount}
              />
              <Space
                className={`quotes pb ${showingTdsId ? 'is-showing-tds' : ''}`}
                direction="vertical"
                size={16}
              >
                <Row className="header-row mx-0" gutter={[16, 0]} justify="end">
                  <BoxColumn width={LIST_BOX_COLUMN_WIDTH}>
                    <Typography.Text className="block font-size-2 heavy">
                      Total Cost
                    </Typography.Text>
                    <Typography.Text className="font-size-1 block">
                      Raw material, tariffs &amp; fees, freight
                    </Typography.Text>
                  </BoxColumn>
                  <BoxColumn width={LIST_BOX_COLUMN_WIDTH}>
                    <Typography.Text className="block font-size-2 heavy">
                      Quality
                    </Typography.Text>
                    <Typography.Text className="font-size-1 block">
                      Meets primary specs
                    </Typography.Text>
                  </BoxColumn>
                  <BoxColumn width={LIST_BOX_COLUMN_WIDTH}>
                    <Typography.Text className="block font-size-2 heavy">
                      Quantity
                    </Typography.Text>
                    <Typography.Text className="font-size-1 block">
                      Available capacity
                    </Typography.Text>
                  </BoxColumn>
                  <BoxColumn width={LIST_BOX_COLUMN_WIDTH}>
                    <Typography.Text className="block font-size-2 heavy">
                      Brand fit
                    </Typography.Text>
                    <Typography.Text className="font-size-1 block">
                      Meets certification requirements
                    </Typography.Text>
                  </BoxColumn>
                </Row>
                {responseTDSs
                  // For some reason the .map function is giving a `cannot read properties of undefined` if we don't filter?
                  .filter((tds) => !!tds.company)
                  .map((tds) => {
                    const preferredLocation =
                      tds.project_tds?.tds_location || tds.locations[0];
                    return (
                      <Row
                        key={tds.uuid}
                        className={`mx-0 single-quote ${
                          showingTdsId === tds.uuid ? 'shown-tds' : ''
                        }`}
                        gutter={[16, 0]}
                        wrap={false}
                        onClick={() => setShowingTdsId(tds.uuid)}
                      >
                        <Col flex="1">
                          <Row gutter={[12, 0]} wrap={false} align="middle">
                            <Col>
                              <Avatar
                                icon={<UserOutlined />}
                                src={tds.company.avatar}
                              />
                            </Col>
                            <Col>
                              <div>
                                <Typography.Text
                                  style={{ lineHeight: '20px' }}
                                  strong
                                >
                                  {tds.company.name}
                                </Typography.Text>
                                {tagForTds(tds)}
                              </div>
                              <div>
                                <Typography.Text
                                  style={{ fontSize: 12, lineHeight: '12px' }}
                                  type="secondary"
                                >
                                  {locationRender(preferredLocation)}
                                </Typography.Text>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <BoxColumn width={130}>
                          <EditableMatch
                            filters={filters}
                            projectUuid={projectUuid}
                            settingsKey="total_price"
                            tdsUuid={tds.uuid}
                          >
                            {tdsPriceText(tds, benchmarkAllIn)}
                          </EditableMatch>
                        </BoxColumn>
                        <BoxColumn width={LIST_BOX_COLUMN_WIDTH}>
                          <EditableMatchIcon
                            filters={filters}
                            projectUuid={projectUuid}
                            settingsKey="specs_match"
                            tdsUuid={tds.uuid}
                          />
                        </BoxColumn>
                        <BoxColumn width={LIST_BOX_COLUMN_WIDTH}>
                          <EditableMatchIcon
                            filters={filters}
                            projectUuid={projectUuid}
                            settingsKey="supply_match"
                            tdsUuid={tds.uuid}
                          />
                        </BoxColumn>
                        <BoxColumn width={LIST_BOX_COLUMN_WIDTH}>
                          <EditableMatchIcon
                            filters={filters}
                            projectUuid={projectUuid}
                            settingsKey="brand_match"
                            tdsUuid={tds.uuid}
                          />
                        </BoxColumn>
                      </Row>
                    );
                  })}
              </Space>
            </Space>
          </Flex>
          {!!showingTdsId && (
            <Flex align="start" className="search--proposal--details" vertical>
              <QuoteDetails
                onClose={() => setShowingTdsId(null)}
                project={project}
                tds={responseTDSs.find(({ uuid }) => uuid === showingTdsId)}
              />
            </Flex>
          )}
        </Flex>
      </FullScreenModal>
    </>
  );
}
ProposalModal.propTypes = {};

const matchOptions = [
  {
    key: 'match',
    label: 'Match',
    icon: <CheckCircleFilled className="check-circle-filled success" />
  },
  {
    key: 'nomatch',
    label: 'No match',
    icon: <CheckCircleFilled className="check-circle-filled warning" />
  }
];

function EditableMatchIconXOverY({
  x,
  y,
  filters,
  projectUuid,
  settingsKey,
  tdsUuid
}) {
  const { data: project } = useGetPopulatedProject(projectUuid, filters);

  const { settings = {} } = project ?? {};

  const match = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[settingsKey] ?? false,
    [settings]
  );
  const type = match ? 'success' : 'danger';
  return (
    <EditableMatch
      filters={filters}
      projectUuid={projectUuid}
      settingsKey={settingsKey}
      tdsUuid={tdsUuid}
    >
      <Typography.Text strong>{x}</Typography.Text>
      <Typography.Text type={type}>{` / ${y}`}</Typography.Text>
    </EditableMatch>
  );
}

EditableMatchIconXOverY.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  filters: PropTypes.object,
  projectUuid: PropTypes.string,
  settingsKey: PropTypes.string,
  tdsUuid: PropTypes.string
};

function EditableMatch({
  children,
  filters,
  projectUuid,
  settingsKey,
  tdsUuid
}) {
  const { data: project } = useGetPopulatedProject(projectUuid, filters);
  const { mutate, messageContext } = useUpdateProject(projectUuid, filters);

  const { settings = {} } = project ?? {};

  const match = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[settingsKey] ?? false,
    [settings]
  );
  const type = match ? 'success' : 'danger';

  return (
    <>
      {messageContext}
      <Dropdown
        trigger="hover"
        menu={{
          items: matchOptions,
          onClick: ({ key, domEvent }) => {
            domEvent.stopPropagation();

            const updatedSettings = {
              ...settings,
              submittedTdss: {
                ...(settings?.submittedTdss ?? {}),
                [tdsUuid]: {
                  ...(settings?.submittedTdss?.[tdsUuid] ?? {}),
                  [settingsKey]: key === 'match'
                }
              }
            };

            mutate({
              uuid: projectUuid,
              settings: updatedSettings
            });
          }
        }}
      >
        <Box type={type}>{children}</Box>
      </Dropdown>
    </>
  );
}

EditableMatch.propTypes = {
  children: PropTypes.node,
  filters: PropTypes.object,
  projectUuid: PropTypes.string,
  settingsKey: PropTypes.string,
  tdsUuid: PropTypes.string
};

function EditableMatchIcon({ filters, projectUuid, settingsKey, tdsUuid }) {
  const { data: project } = useGetPopulatedProject(projectUuid, filters);

  const { settings = {} } = project ?? {};

  const match = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[settingsKey] ?? false,
    [settings]
  );
  const type = match ? 'success' : 'danger';
  const icon = match ? <CheckCircleFilled /> : <InfoCircleFilled />;
  return (
    <EditableMatch
      filters={filters}
      projectUuid={projectUuid}
      settingsKey={settingsKey}
      tdsUuid={tdsUuid}
    >
      {icon}
    </EditableMatch>
  );
}

EditableMatchIcon.propTypes = {
  filters: PropTypes.object,
  projectUuid: PropTypes.string,
  settingsKey: PropTypes.string,
  tdsUuid: PropTypes.string
};
