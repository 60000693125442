import React from 'react';
import numericRange from 'src/components/utils/numericRange';

export function useExplainToText(explain) {
  if (!explain) return undefined;
  function explainItemDetails(item) {
    const keys = Object.keys(item);
    const key = keys[0];
    if (key === 'properties') {
      const property = item.properties;
      const propertyCode = Object.keys(property)[0];
      const propertyValue = property[propertyCode];
      return `${propertyCode} of ${propertyValue.join(', ')}`;
    }
    if (key === 'kpis') {
      const property = item.kpis;
      const propertyCode = Object.keys(property)[0];
      const propertyValue = property[propertyCode];
      const { units } = propertyValue;
      const unitDisplay = units || '';

      return `${propertyCode} of ${numericRange({
        ...propertyValue,
        empty: '(any value)',
        rangeRender: (min, max) => `${min} - ${max} ${unitDisplay}`,
        noMinRender: (v) => `${v} or - ${unitDisplay}`,
        noMaxRender: (v) => `${v} or + ${unitDisplay}`,
        singleValueRender: (v) => `${v} ${unitDisplay}`
      })}`;
    }
    return '';
  }

  function explainItemType(item) {
    const keys = Object.keys(item);
    const key = keys[0];
    const type =
      key === 'properties'
        ? 'property'
        : key === 'kpis'
        ? 'specification'
        : 'item';
    return type;
  }
  const individuals = explain
    .filter((e) => e.filters.length === 1)
    .map((e) => e.filters[0]);
  const combinations = explain
    .filter((e) => e.filters.length > 1)
    .map((e) => e.filters);
  const individualContent =
    individuals.length === 0 ? (
      ''
    ) : (
      <>
        <p>The following individual requirements are not easily available:</p>
        <ul>
          {individuals.map((item, index) => (
            <li key={index}>{explainItemDetails(item)}</li>
          ))}
        </ul>
      </>
    );
  const combinationsContent =
    combinations.length === 0 ? (
      ''
    ) : (
      <>
        <p>
          The following combinations of requirements are not easily available:
        </p>
        <ul>
          {combinations.map((item, index) => (
            <li key={index}>
              {item.map((sub) => explainItemDetails(sub)).join(', ')}
            </li>
          ))}
        </ul>
      </>
    );
  if (!individualContent && !combinationsContent)
    return <p>Your specifications are available</p>;
  return (
    <>
      {individualContent}
      {combinationsContent}
    </>
  );
}
